// import logoText from "../assets/images/LogoText.png";
import logoText from "../assets/images/claima_stacked_black.png";

const LogoHook = (props) => {
  let style = {
    // width: "120px",
    width: "100px",
    // height: "120px",
    height: "70px",
  };

  return (
    <>
      <div style={{margin: '0 0 30px 0'}}>
        <img src={logoText} style={style} alt="Aman Medicare" />
      </div>
    </>
  );
};

export default LogoHook;
